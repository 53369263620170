import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk-edit"
export default class extends Controller {
  static targets = ["checkbox", "actions", "selectAll"]

  connect() {
    this.updateBulkActionsVisibility()
  }

  toggleBulkActions(event) {
    this.updateBulkActionsVisibility()
    // Update select all checkbox state
    if (this.hasSelectAllTarget) {
      this.selectAllTarget.checked = this.checkboxTargets.every(cb => cb.checked)
    }
  }

  toggleAll(event) {
    const checked = event.target.checked
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = checked
    })
    this.updateBulkActionsVisibility()
  }

  updateBulkActionsVisibility() {
    const checkedBoxes = this.checkboxTargets.filter(cb => cb.checked)
    if (checkedBoxes.length > 0) {
      this.actionsTarget.classList.remove('is-hidden')
    } else {
      this.actionsTarget.classList.add('is-hidden')
    }
  }
}
