import { Controller } from "@hotwired/stimulus"
import * as WebAuthnJSON from "@github/webauthn-json"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="webauthn--auth"
export default class extends Controller {
  static values = { callback: String }

  auth(event) {
    const [data] = event.detail;

    WebAuthnJSON.get({ "publicKey": data }).then(async (credential) => {
      const request = new FetchRequest("post", this.callbackValue, { body: JSON.stringify(credential) })
      const response = await request.perform()

      if (response.ok) {
        const data = await response.json
        window.Turbo.visit(data.redirect, { action: 'replace' })
      } else {
        console.log("something is wrong", response);
      }
    }).catch((error) => {
      console.log("something is wrong", error);
    });
  }

  error(event) {
    console.log("something is wrong", event);
  }
}
